.file-controller {
  width: 95vw;
  max-width: 600px;
  margin: 0 auto;

  &.pc {
    display: flex;
    flex-direction: row;
    max-width: 940px;
  }

  .file-send {
    margin: 24px 0;
    padding: 8px 24px 0;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.5);

    &.pc {
      flex: 1;
      margin-right: 12px;
    }

    label {
      color: #333;
      font-size: 14px;
    }

    button.send-button {
      display: block;
      width: 100%;
      margin: 12px auto;
      padding: 8px 0;
      border: 0.55px solid #fff;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0);
      // 084 Phoenix Start
      // background-image: linear-gradient(to right, #f83600 0%, #f9d423 100%);
      // 102 Happy Memories
      background-image: linear-gradient(-60deg, #ff5858 0%, #f09819 100%);

      color: #fff;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      text-align: center;
      transition: all 0.2s ease-in-out;
      box-sizing: border-box;

      &:hover {
        // background: rgba(255,255,255,.1);
        // 102 Happy Memories
        // background-image: linear-gradient(-60deg, #ff5858 0%, #f09819 100%);
        color: #eee;
        opacity: 0.8;
      }

      &.disable {
        border-color: rgba(255, 255, 255, 0.6);
        background: rgba(255, 255, 255, 0);
        color: rgba(255, 255, 255, 0.6);
        cursor: default;

        &:hover {
          background: rgba(255, 255, 255, 0);
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }

    .file-input {
      margin: 8px auto;
      // margin: 32px auto;

      label.file {
        display: block;
        width: 100%;
        margin: 0 auto;
        padding: 8px 0;
        border-radius: 8px;
        // 084 Phoenix Start
        background: rgba(255, 255, 255, 0.5);
        // background-image: linear-gradient(to right, #f83600 0%, #f9d423 100%);
        color: #333;
        font-size: 16px;
        cursor: pointer;
        text-align: center;
        transition: all 0.1s ease-in-out;
        box-sizing: border-box;

        i {
          margin-right: 0.2em;
          color: #666;
        }

        span {
          display: block;
          font-size: 0.6em;
          color: #666;
        }

        &:hover {
          // 102 Happy Memories
          // background-image: linear-gradient(-60deg, #ff5858 0%, #f09819 100%);
          background: rgba(255, 255, 255, 0.6);
        }

        input.file {
          display: none;
        }
      }
    }

    ul.send-file-list {
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      padding: 0 0 16px;
      list-style: none;

      li {
        -webkit-animation: fadein 0.2s 1 ease;
        animation: fadein 0.2s 1 ease;

        &.send-filelist {
          margin: 8px auto;
          padding: 0 24px 24px;
          border-radius: 8px;
          background: rgba(255, 255, 255, 0.5);
          cursor: default;
          transition: background-color 0.2s ease-in-out 0s;

          &:hover {
            background: rgba(255, 255, 255, 0.6);
          }

          .send-status {
            text-align: right;
            font-size: 12px;
            height: 24px;

            & > span {
              display: inline-block;
              padding: 2px 6px;
              background: rgba(0, 0, 0, 0.1);
              transition: background-color 0.2s ease-in-out 0s;
              cursor: pointer;

              &:hover {
                background: rgba(255, 255, 255, 0.6);
              }

              span.delete {
                margin-left: 6px;
                color: #666;
              }
            }

            &.complete > span {
              color: #fff;
              background: rgba(56, 128, 255, 0.6);

              &:hover {
                background: rgba(56, 128, 255, 0.8);
              }

              span.delete {
                color: #fff;
              }
            }

            &.failed > span {
              color: #fff;
              background: rgba(240, 65, 65, 0.6);

              &:hover {
                background: rgba(240, 65, 65, 0.8);
              }

              span.delete {
                color: #fff;
              }
            }
          }

          .send-info {
            display: flex;

            .file-icon {
              width: 48px;
              margin-right: 24px;

              display: flex;
              justify-content: center;
              align-items: center;

              i {
                color: #888;
                font-size: 32px;
              }

              .send-percent {
                position: absolute;
                margin: 0;
                padding: 0 4px;
                border-radius: 10px;
                // font-family: fira-mono,monospace;
                // font-family: source-code-pro, monospace;
                font-family: ocr-b-std, monospace;
                font-size: 12px;
                text-align: right;
                background: rgba(255, 255, 0, 0.8);

                &.standby {
                  opacity: 0;
                }

                &.sending {
                  // transition: all .1s ease-in-out 0s;
                }

                &.complete {
                  opacity: 0;
                  background: rgba(255, 255, 255, 0.8);
                  transition: all 2s ease-in-out 0s;
                }
              }
            }

            .detail {
              flex: 1;

              .file-name {
                font-size: 14px;
                // font-family: "Hiragino Kaku Gothic ProN","メイリオ", sans-serif;
                // font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
                // font-family: ocr-b-std, monospace;
                font-family: 'Noto Sans JP', sans-serif;
                line-height: 16px;
                word-break: break-all;
              }

              .send-size {
                margin: 2px 0 0 0;
                padding: 0;
                font-family: ocr-b-std, monospace;
                font-size: 12px;
                color: #888;
                line-height: 14px;

                display: flex;
                justify-content: space-between;

                span {
                  display: block;
                }
              }

              // progress-bar
            }
          }
        }

        // 削除済みファイル
        // &=li
        &.send-filelist.deleted {
          margin: 8px auto;
          padding: 0 24px 24px;
          border-radius: 8px;
          background: rgba(255, 255, 255, 0.2);
          cursor: default;
          transition: background-color 0.6s ease-in-out 0s;

          &:hover {
            background: rgba(255, 255, 255, 0.2);
          }

          .send-status {
            color: #000;

            & > span {
              background: rgba(255, 255, 255, 0.2);
              cursor: default;

              &:hover {
                background: rgba(255, 255, 255, 0.2);
              }
            }
          }

          .send-info {
            .file-icon {
              i {
                color: rgba(0, 0, 0, 0.1);
                transition: color 0.6s ease-in-out 0s;
              }
            }
          }
          .detail {
            .file-name {
              color: #888;
              transition: color 0.6s ease-in-out 0s;
            }
            .send-size {
              color: #aaa;
              transition: color 0.6s ease-in-out 0s;
            }
          }
        }
      }
    }

    .no-file {
      margin: 24px auto 0;
      padding: 0 0 24px;
      font-size: 14px;
      text-align: center;
      color: #666;

      p {
        margin: 0;
        padding: 0;
      }
    }

    // button {
    //   outline: none;
    //   appearance: none;
    //   margin: 0;
    //   padding: 0;
    //   border: 0;
    //   cursor: pointer;
    // }

    // button.standby {
    //   background-image: linear-gradient(to right, #f83600 0%, #f9d423 100%);

    //   &:hover {
    //     opacity: .6;
    //   }
    // }
  }

  .file-receive {
    margin: 24px 0;
    padding: 8px 24px 0;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.5);

    &.pc {
      flex: 1;
      margin-left: 12px;
    }

    label {
      color: #333;
      font-size: 14px;
    }

    .no-file {
      margin: 24px 0;
      color: #666;

      p {
        margin: 0;
        padding: 0;
      }
    }

    ul.receive-file-list {
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      padding: 0 0 16px;
      list-style: none;

      li {
        -webkit-animation: fadein 0.2s 1 ease;
        animation: fadein 0.2s 1 ease;

        &.receive-filelist {
          margin: 8px auto;
          padding: 0 24px 24px;
          border-radius: 8px;
          background: rgba(255, 255, 255, 0.5);
          cursor: default;
          transition: background-color 0.2s ease-in-out 0s;

          &:hover {
            background: rgba(255, 255, 255, 0.6);
          }

          .receive-status {
            text-align: right;
            font-size: 12px;
            height: 24px;

            span {
              display: inline-block;
              padding: 2px 6px;
              color: #333;
              background: rgba(0, 0, 0, 0.1);
            }

            &.complete span {
              color: #fff;
              background: rgba(56, 128, 255, 0.6);
            }

            &.failed span {
              color: #fff;
              background: rgba(240, 65, 65, 0.6);
            }
          }

          .receive-info {
            display: flex;

            .file-icon {
              width: 48px;
              margin-right: 24px;

              display: flex;
              justify-content: center;
              align-items: center;

              i {
                color: #888;
                font-size: 32px;
              }

              .receive-percent {
                position: absolute;
                margin: 0;
                padding: 0 4px;
                border-radius: 10px;
                // font-family: fira-mono,monospace;
                // font-family: source-code-pro, monospace;
                font-family: ocr-b-std, monospace;
                font-size: 12px;
                text-align: right;
                background: rgba(255, 255, 0, 0.8);

                &.standby {
                  opacity: 0;
                }

                &.receiving {
                  // transition: all .1s ease-in-out 0s;
                }

                &.complete {
                  opacity: 0;
                  background: rgba(255, 255, 255, 0.8);
                  transition: all 2s ease-in-out 0s;
                }
              }
            }

            .detail {
              flex: 1;

              .file-name {
                font-size: 14px;
                // font-family: "Hiragino Kaku Gothic ProN","メイリオ", sans-serif;
                // font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
                // font-family: ocr-b-std, monospace;
                font-family: 'Noto Sans JP', sans-serif;
                line-height: 16px;
                word-break: break-all;

                // 受信完了前
                span {
                  color: #888;
                }

                a {
                  color: #333;
                  text-decoration: none;

                  &:hover {
                    text-decoration: underline;
                  }
                }
              }

              .receive-size {
                margin: 2px 0 0 0;
                padding: 0;
                font-family: ocr-b-std, monospace;
                font-size: 12px;
                color: #888;
                line-height: 14px;
                display: flex;
                justify-content: space-between;

                span {
                  display: block;
                }
              }

              // progress-bar
            }
          }
        }

        &.receive-filelist.complete {
          padding: 0;

          &:hover {
            background: rgba(255, 255, 255, 0.8);
          }

          a {
            display: block;
            padding: 0 24px 24px;
            color: #333;
            text-decoration: none;

            &:hover .detail .file-name {
              text-decoration: underline;
            }
          }
        }

        // 削除済みファイル
        &.receive-filelist.deleted {
          margin: 8px auto;
          padding: 0 24px 24px;
          border-radius: 8px;
          background: rgba(255, 255, 255, 0.2);
          cursor: default;
          transition: background-color 0.6s ease-in-out 0s;

          &:hover {
            background: rgba(255, 255, 255, 0.2);
          }

          .receive-status {
            color: #000;

            & > span {
              background: rgba(255, 255, 255, 0.2);
              cursor: default;

              &:hover {
                background: rgba(255, 255, 255, 0.2);
              }
            }
          }

          .receive-info {
            .file-icon {
              i {
                color: rgba(0, 0, 0, 0.1);
                transition: color 0.6s ease-in-out 0s;
              }
            }
          }
          .detail {
            .file-name {
              color: #888;
              transition: color 0.6s ease-in-out 0s;
            }
            .receive-size {
              color: #aaa;
              transition: color 0.6s ease-in-out 0s;
            }
          }
        }
      }
    }

    .no-file {
      margin: 24px auto 0;
      padding: 0 0 24px;
      font-size: 14px;
      text-align: center;
      color: #666;

      p {
        margin: 0;
      }
    }

    button {
      outline: none;
      appearance: none;
      margin: 0;
      padding: 0;
      border: 0;
      cursor: pointer;
    }

    // button.standby {
    //   background-image: linear-gradient(to right, #f83600 0%, #f9d423 100%);

    //   &:hover {
    //     opacity: .6;
    //   }
    // }
  }

  .progress-bar {
    position: relative;
    margin: 0;
    padding: 0;

    &.standby {
      opacity: 0;
    }

    &.sending &.receiving {
      transition: all 0.1s ease-in-out 0s;
    }

    &.complete {
      opacity: 0;
      background: rgba(255, 255, 255, 0.8);
      transition: all 0.5s ease-in-out 0s;
    }

    .progress {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      border-radius: 2px;
      z-index: 2;
      background: linear-gradient(rgba(3, 213, 250, 1), rgba(3, 213, 250, 1)) no-repeat rgba(255, 255, 255, 0.8);
      background-size: 0% 100%;
    }
  }
}
