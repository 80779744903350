.input {
  input {
    display: block;
    width: 50%;
    margin: 0 auto;
    padding: 8px;
    border: 0;
    font-size: 24px;
    text-align: center;
    box-sizing: border-box;
    letter-spacing: 8px;
    background: rgba(255, 255, 255, 0.6);

    &:hover {
      background: rgba(255, 255, 255, 0.7);
    }

    &:focus {
      background: rgba(255, 255, 255, 0.9);
    }
  }
}
