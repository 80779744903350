header {
  width: 100%;
  height: 90px;
  background: #fff;

  // 055 Sharp Blues
  // background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);

  // 030 Happy Fisher
  // background-image: linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%);

  & > div {
    max-width: 940px;
    height: 90px;
    margin: 0 auto;
    padding: 0 16px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      display: block;
      margin: 0;
      padding: 0;
      color: #333;
      font-size: 1.2rem;
      font-weight: normal;

      a {
        color: #333;
        text-decoration: none;

        &:hover {
          color: #666;
          text-decoration: underline;
        }
      }
    }

    span {
      display: block;

      a {
        display: block;
        margin: 0;
        padding: 6px 16px;
        border-radius: 8px;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        background: rgba(56, 128, 255, 1);
        transition: background-color 0.2s ease-out 0s;
        text-decoration: none;

        &:hover {
          background: rgba(56, 128, 255, 0.8);
        }
      }
    }
  }
}

footer {
  margin: 48px 0;
  width: 100%;

  .title {
    max-width: 940px;
    margin: 0 auto 12px;
    padding: 0 16px;
  }

  h2 {
    display: flex;
    align-items: center;
    margin: 24px auto 12px;
    font-size: 16px;
    font-weight: normal;

    a {
      text-decoration: none;
      color: #333;

      &:hover {
        text-decoration: underline;
      }
    }

    .version {
      display: inline-block;
      margin-left: 6px;
      padding: 2px 6px;
      border-radius: 6px;
      background: #eee;
      font-size: 12px;
    }
  }

  .author {
    max-width: 940px;
    margin: 0 auto;

    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      display: block;
      width: 50px;
      height: 50px;
      margin: 0 0 0 12px;
      border-radius: 100%;
    }

    p {
      margin: 0 0 0 8px;
      color: #666;

      a {
        display: inline-block;
        margin: 0 8px;
        color: #333;
        text-decoration: none;
        transition: color 0.2s ease-out 0s;

        &:hover {
          color: #1da1f2;
        }
      }
    }
  }
}
