@import './_color.scss';

body {
  margin: 0;
  padding: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Hiragino Sans', 'Hiragino Kaku Gothic ProN', '游ゴシック  Medium', meiryo, sans-serif;
  // note.mu より
  // font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", Arial, "メイリオ", Meiryo, sans-serif;
  // zatsuzen.comより
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Hiragino Kaku Gothic ProN, 'メイリオ',
    meiryo, sans-serif;

  line-height: 1.6;
  -webkit-touch-callout: none;
  // -webkit-user-select: none;
}

input {
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', Hiragino Kaku Gothic ProN,
    'ヒラギノ角ゴ ProN W3', Arial, 'メイリオ', Meiryo, sans-serif;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
}

a {
  -webkit-touch-callout: none;
}

input:focus,
input[type='password']:focus {
  // border: solid 1px $base-color!important;
  outline: 0;
}

// ::selection {
//   background: hsla(358,100,36%,.4);
//   color: #ffffff;
// }
// ::-moz-selection {
//   background: hsla(358,100,36%,.4);
//   color: #ffffff;
// }

div {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
}

button {
  outline: none;
  appearance: none;
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Hiragino Kaku Gothic ProN, 'メイリオ',
    meiryo, sans-serif;
}

::selection {
  background: rgba(56, 128, 255, 0.6);
  color: #ffffff;
}
::-moz-selection {
  background: rgba(56, 128, 255, 0.6);
  color: #ffffff;
}

// リストの表示に使用
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-animation: fadein 0.1s 1 ease-out;
  animation: fadein 0.1s 1 ease-out;
}

.react-confirm-alert-body {
  width: 270px;
  padding: 0;
  background: #fcfcfc;
  border-radius: 8px;
  color: #000;

  & > h1 {
    padding: 16px 16px;
    margin: 8px 0 0 0;
    font-size: 17px;
    font-weight: 600;
    text-align: center;
  }
}

.react-confirm-alert-button-group {
  display: flex;
  justify-content: flex-start;
  margin: 0;
  border-top: 0.55px solid #dbdbdf;

  & > button {
    display: inline-block;
    width: 50%;
    height: 44px;
    margin: 0;
    padding: 6px 18px;
    border: none;
    background: #f8f8f8;
    font-size: 17px;
    cursor: pointer;
    outline: none;
    &:first-child {
      border-right: 0.55px solid #dbdbdf;
      border-radius: 0 0 0 8px;
      color: #488aff;
      font-weight: 600;
    }
    &:last-child {
      border-radius: 0 0 8px 0;
      color: #f53d3d;
    }
  }
}

.react-confirm-alert {
  -webkit-animation: fadein 0.2s 1 ease-out;
  animation: fadein 0.2s 1 ease-out;

  .alert {
    width: 270px;
    padding: 0;
    background: #f8f8f8;
    border-radius: 8px;
    color: #000;

    & > h1 {
      padding: 12px 16px 7px;
      margin: 8px 0 0 0;
      font-size: 17px;
      font-weight: 600;
      text-align: center;
    }

    & > p {
      padding: 0 16px 21px;
      margin: 0;
      text-align: center;
      font-size: 13px;
    }

    & > .button-group {
      display: flex;
      justify-content: flex-start;
      margin: 0;
      border-top: 0.55px solid #dbdbdf;

      button {
        display: inline-block;
        width: 50%;
        height: 44px;
        margin: 0;
        padding: 6px 18px;
        border: none;
        background: #f8f8f8;
        font-size: 17px;
        cursor: pointer;
        outline: none;
        &:first-child {
          border-right: 0.55px solid #dbdbdf;
          border-radius: 0 0 0 8px;
          color: #488aff;
          font-weight: 600;
        }
        &:last-child {
          border-radius: 0 0 8px 0;
          color: #f53d3d;
        }
        &:hover {
          background: #e9e9e9;
        }
      }
    }
  }
}

// よくわからないけどsvgが表示されてしまうのでその対策
svg#react-confirm-alert-firm-svg {
  display: none;
}

.full-loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  // flex-direction: row;
  justify-content: center;
  align-items: center;
}

.loading {
  margin: 16px auto;
  width: 60px;
  display: flex;
  justify-content: space-between;

  & > div {
    width: 16px;
    height: 16px;
    background-color: #ccc;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .loading1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .loading2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

// Editor, Article共通
.main-article {
  max-width: 800px;
  margin: 0 auto;

  .title {
    margin: 32px 0;
    padding: 16px 0;
    text-align: center;
  }

  .title-preview {
    text-align: center;
  }

  .update-time {
    margin: 8px 0;
    padding: 8px 0;
    font-size: 11px;
    color: #898d8c;

    & > div {
      margin: 0 16px;

      span {
        display: inline-block;
        margin-right: 4px;
      }

      i {
        display: inline-block;
        margin-right: 0.2em;
        // color: #666;
      }
    }
  }

  .contents {
    padding: 16px 0 16px;
    // github より
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol;
    color: #24292e;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 16px;
    }

    h1,
    h2 {
      padding: 0;
      border-bottom: 1px solid #eaecef;
      padding-bottom: 0.3em;
    }

    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 16px;
      margin-top: 24px;
    }

    p {
      margin: 12px 16px;
      padding: 0;
      font-size: 16px;
      line-height: 30px;
      word-wrap: break-word;

      code {
        background-color: rgba(27, 31, 35, 0.05);
        margin: 0 0.2em;
        padding: 0.1em 0.4em;
        border-radius: 2px;
        font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
      }
    }

    pre {
      display: block;
      margin: 20px 0;
      padding: 0 0 16px;
      border: none;
      border-radius: 0;

      color: rgba(255, 255, 255, 0.85);
      background: #364549;
      line-height: 1.6;
      overflow-x: auto;

      word-break: break-all;
      word-wrap: break-word;

      code {
        display: block;
        margin: 0 0.5em;
        padding: 16px 16px 0;
        font-size: 85%;
        font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;

        &.filename {
          display: inline-block;
          margin: 0 0 0 16px;
          padding: 2px 4px;
          background: #666;
          color: #eee;

          & + * {
            display: block;
          }
        }
      }
    }

    hr {
      background-color: #e1e4e8;
      border: 0;
      height: 0.25em;
      margin: 24px 0;
      padding: 0;
    }

    blockquote {
      margin: 16px;
      padding: 24px 36px;
      font-size: 16px;
      line-height: 36px;
      background-color: #f7f9f9;

      p {
        margin: 0;
      }
    }

    a {
      color: #0366d6;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
