.home {
  .main {
    min-height: calc(100vh - 90px);
    padding: 0 0 48px;

    // 030 Happy Fisher
    background-image: linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%);

    .title {
      width: 100%;
      max-width: 600px;
      min-height: 40vh;
      margin: 0 auto;

      display: flex;
      justify-content: center;
      align-items: center;

      & > div {
        h1 {
          text-align: center;
        }

        p {
          margin: 24px 0 0;
          padding: 0;
          text-align: center;
        }
      }
    }

    .start {
      max-width: 940px;
      margin: 0 auto 10vh;
      padding: 0 16px;
      display: flex;
      justify-content: center;

      @media (max-width: 767px) {
        & {
          flex-direction: column;
          align-items: center;
        }
      }

      & > div {
        width: 45%;
        background: hsla(0, 0%, 100%, 0.5);
        box-shadow: none;
        text-align: center;
        transition: all 0.2s;
        box-sizing: border-box;

        @media (max-width: 767px) {
          & {
            width: 100%;
            max-width: 500px;
          }
        }

        & > div {
          margin: 24px 0;
          padding: 24px;

          @media (max-width: 767px) {
            & {
              margin: 0 24px;
              padding: 36px 24px;
            }
          }
        }

        &.host {
          border-radius: 8px 0 0 8px;

          @media (max-width: 767px) {
            & {
              border-radius: 8px 8px 0 0;
            }
          }

          & > div {
            border-right: 0.55px solid #ccc;
            border-bottom: 0;

            @media (max-width: 767px) {
              & {
                border-right: 0;
                border-bottom: 0.55px solid #ccc;
              }
            }

            a {
              display: inline-block;
              width: 100%;
              margin: 0;
              border-radius: 8px;
              line-height: 44px;
              color: #fff;
              font-size: 14px;
              font-weight: bold;
              background: rgba(56, 128, 255, 1);
              transition: all 0.2s ease-out 0s;
              text-decoration: none;
              text-align: center;

              &:hover {
                background: rgba(56, 128, 255, 0.8);
              }
            }
          }
        }

        &.guest {
          border-radius: 0 8px 8px 0;

          @media (max-width: 767px) {
            & {
              border-radius: 0 0 8px 8px;
            }
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          input[type='number'] {
            -moz-appearance: textfield;
          }

          input {
            width: 100%;
            border-radius: 8px;
          }
        }

        &:hover {
          background: hsla(0, 0%, 100%, 0.6);
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
        }

        h2 {
          text-align: center;
          margin: 0;
          font-weight: normal;
          font-size: 16px;
        }

        .text {
          color: #666;
          font-size: 14px;
        }
      }
    }

    .guide {
      width: 95%;
      max-width: 600px;
      margin: 0 auto;

      h3 {
        display: block;
        margin: 24px 12px 12px;
      }

      p {
        display: block;
        margin: 0 12px;
        font-size: 16px;

        a {
          color: #333;
          text-decoration: none;

          &:hover {
            color: #666;
            text-decoration: underline;
          }
        }
      }

      .feature {
        margin: 0;
        display: flex;

        &.pc {
          flex-direction: row;
        }

        &.mobile {
          flex-direction: column;
        }

        & > div {
          flex: 1;
          margin: 0 12px 48px;

          .icon {
            margin: 48px 0 12px;
            text-align: center;

            i {
              font-size: 32px;
              color: #333;
            }
          }

          h3 {
            margin: 0;
            padding: 0;
            font-size: 16px;
            // font-weight: normal;
            text-align: center;
            color: #333;
          }

          p {
            margin: 1em 0 0;
            font-size: 16px;
            line-height: 24px;
          }

          p.webrtc {
            margin: 0.5em 0 0;
            font-size: 14px;
            line-height: 18px;
            text-align: center;

            a.webrtc {
              display: inline-block;
              margin: 0 auto;
              padding: 6px 16px;
              border-radius: 8px;
              color: #000;
              background: rgba(255, 255, 255, 0.2);
              transition: background-color 0.2s ease-out 0s;
              text-decoration: none;

              &:hover {
                background: rgba(255, 255, 255, 0.5);
              }
            }
          }
        }
      }

      .browsers {
        display: flex;
        justify-content: space-around;
        align-items: center;
        align-content: flex-start;
        flex-wrap: wrap;

        .browser {
          width: 60px;
          min-width: 60px;
          flex: 1;
          margin: 12px 0;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          i {
            display: block;
            font-size: 32px;
            color: #333;
          }

          span {
            display: block;
            color: #333;
            font-size: 12px;
          }
        }
      }
    }
  }
}
