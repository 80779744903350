.tutorial {
  width: 95vw;
  max-width: 600px;
  margin: 0 auto 24px;
  padding: 24px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
  box-sizing: border-box;

  &.pc {
    max-width: 940px;
  }

  h3 {
    margin: 0 0 8px;
    padding: 0;
    font-size: 16px;
    font-weight: normal;
  }

  p {
    font-size: 14px;
    color: #333;
  }

  ol {
    margin: 0;
    padding: 0 0 0 20px;

    li {
      font-size: 14px;
      color: #333;
    }
  }
}
